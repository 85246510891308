import React, { useState, useEffect, useRef } from 'react';
import { BiXCircle } from 'react-icons/bi';
import { useSelector, useDispatch } from 'react-redux';
import LoadingIcons from 'react-loading-icons';
import '../../OrdersStyle.css';
import { Divider } from 'antd';
import * as moment from 'moment';
import { retrieveLocationDetails } from 'store/modules/location';
import { retrieveVendorDetails } from 'store/modules/vendor';
import { Popup } from '@progress/kendo-react-popup';
import { cancelOrder, clearCancelOrder } from 'store/modules/order';
// import PropTypes from 'prop-types';

export default function OrderDetails(props) {
  const dispatch = useDispatch();
  const {
    loadingOrderDetails,
    selectedOrder,
    cancelOrderMessage,
    cancelledOrder,
    cancelOrderError,
  } = useSelector((state) => state.orders);

  const anchor = useRef(null);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showStatusPopUp, setshowStatusPopUp] = useState(false);

  const { selectedLocation } = useSelector((state) => state.locations);
  const { selectedVendor } = useSelector((state) => state.vendors);
  const [newOrderDetails, setNewOrderDetails] = useState();
  const [orderID, setOrderID] = useState();

  const returnHome = () => {
    props.returnBack();
    props.onModeChange('list');
  };

  const updateOrder = () => {
    newOrderDetails.vendorName =
      newOrderDetails.vendorName + '-' + newOrderDetails.vendorID;
    props.onModeChange('form');
    props.isUpdateMode('edit', newOrderDetails);
  };

  useEffect(() => {
    if (cancelledOrder === true) {
      setshowStatusPopUp(true);
    }
  }, [cancelOrderMessage, cancelOrderError]);

  const cancelOder = () => {
    setShowPopUp(!showPopUp);
  };

  const offset = {
    // left: '45%',
    // top: '50%',
  };

  const confirmCancelOrder = () => {
    const { id, locationID } = newOrderDetails;
    const formaData = {};
    formaData.id = id;
    formaData.locationID = locationID;
    dispatch(cancelOrder(formaData));
    setShowPopUp(!showPopUp);
  };

  const refreshOrderList = () => {
    setShowPopUp(false);
    dispatch(clearCancelOrder());
    returnHome();
  };

  useEffect(() => {
    if (newOrderDetails !== undefined) {
      newOrderDetails.locationName =
        selectedLocation.locationName === undefined ||
        selectedLocation.locationName === null
          ? ''
          : selectedLocation.locationName;
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (newOrderDetails !== undefined) {
      newOrderDetails.vendorName =
        selectedVendor.vendorName === undefined ||
        selectedVendor.vendorName === null
          ? ''
          : selectedVendor.vendorName;
    }
  }, [selectedVendor]);

  useEffect(() => {
    if (selectedOrder) {
      dispatch(retrieveLocationDetails(selectedOrder));
      dispatch(retrieveVendorDetails(selectedOrder));
      const newStartDate = moment(selectedOrder.deliveryStart).toDate();
      const newEndDate = moment(selectedOrder.deliveryEnd).toDate();
      const newContractType =
        selectedOrder.contractType.charAt(0).toUpperCase() +
        selectedOrder.contractType.slice(1);
      const newSelectedOrder = { ...selectedOrder };
      newSelectedOrder.deliveryStart = newStartDate;
      newSelectedOrder.deliveryEnd = newEndDate;
      newSelectedOrder.contractType = newContractType;
      newSelectedOrder.commoditySymbol =
        newSelectedOrder.commoditySymbol.replace(/\s/g, '');
      newSelectedOrder.accountNameAndNumber = selectedOrder.exchangeAccount;
      if (selectedOrder.optionMonth === 'F') {
        newSelectedOrder.futuresMonth = selectedOrder.optionMonth + ' - Jan';
      } else if (selectedOrder.optionMonth === 'H') {
        newSelectedOrder.futuresMonth = selectedOrder.optionMonth + ' - March';
      } else if (selectedOrder.optionMonth === 'K') {
        newSelectedOrder.futuresMonth = selectedOrder.optionMonth + ' - May';
      } else if (selectedOrder.optionMonth === 'N') {
        newSelectedOrder.futuresMonth = selectedOrder.optionMonth + ' - Jul';
      } else if (selectedOrder.optionMonth === 'Q') {
        newSelectedOrder.futuresMonth = selectedOrder.optionMonth + ' - Aug';
      } else if (selectedOrder.optionMonth === 'U') {
        newSelectedOrder.futuresMonth = selectedOrder.optionMonth + ' - Sep';
      } else if (selectedOrder.optionMonth === 'Z') {
        newSelectedOrder.futuresMonth = selectedOrder.optionMonth + ' - Dec';
      } else {
        newSelectedOrder.futuresMonth = selectedOrder.optionMonth + ' - Oct';
      }
      setNewOrderDetails(newSelectedOrder);
      setOrderID(selectedOrder.id);
    }
  }, [selectedOrder]);

  if (
    loadingOrderDetails === true &&
    selectedOrder === null &&
    selectedVendor === null
  ) {
    <div>
      <LoadingIcons.Oval stroke="#006730" />
    </div>;
  }
  if (
    selectedOrder !== null &&
    loadingOrderDetails === false &&
    selectedVendor !== null
  ) {
    return (
      <div className="order-detail-container">
        <div className="return-button" id="return-button">
          <h2>Order Details</h2>
          <div className="detail-buttons">
            <button className="update-order-btn" onClick={updateOrder}>
              Update Order
            </button>
            <button
              className="cancel-order-btn"
              onClick={cancelOder}
              ref={anchor}
            >
              Cancel Order
            </button>
            <Popup
              anchor={anchor.current}
              show={showPopUp}
              popupClass={'popup-content'}
            >
              <div className="confirm-cancel-order">
                <h3 style={{ marginBottom: '20px', color: 'white' }}>
                  Are you sure you want to cancel order {orderID}?
                </h3>
                {/* <button className="yes-button">Yes</button> */}
                <button className="yes-button" onClick={confirmCancelOrder}>
                  Yes
                </button>
                <button className="no-button" onClick={cancelOder}>
                  No
                </button>
              </div>
            </Popup>
            <BiXCircle
              className="close-button"
              size={40}
              onClick={returnHome}
            />
          </div>
        </div>
        <Divider style={{ margin: '10px' }} />
        <Popup
          show={showStatusPopUp}
          popupClass={'popup-content'}
          offset={offset}
          style={{
            position: 'absolute',
            top: '45%',
            left: '50%',
          }}
        >
          <div className="cancel-success">
            <div className="cancel-message">
              {cancelOrderMessage === 'Entity Updated Successfully'
                ? 'Order Cancelled Successfully'
                : ''}
            </div>
            <div className="cancel-button">
              <button className="ok-button" onClick={refreshOrderList}>
                OK
              </button>
            </div>
          </div>
        </Popup>
        <div className="market-detail-row">
          {/* First Column */}
          <div className="market-detail-column">
            <div className="data-item">
              <label className="order-detail-label">Order ID:</label>
              {' ' + selectedOrder.id}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Region:</label>
              {' ' + selectedOrder.section}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Order Group:</label>
              {' ' + selectedOrder.group}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Submit Date:</label>
              {' ' + selectedOrder.submitDate}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Order Type:</label>
              {' ' + selectedOrder.orderType}
            </div>
            <div className="data-item">
              <label className="order-detail-label">User Name:</label>
              {' ' + selectedOrder.userName}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Contract Type:</label>
              {' ' + selectedOrder.contractType}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Contract Count:</label>
              {' ' + selectedOrder.contractCount}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Board Period:</label>
              {' ' + selectedOrder.boardPeriod}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Quantity:</label>
              {' ' + selectedOrder.quantity}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Commodity:</label>
              {' ' + selectedOrder.commodity}
            </div>
            <div className="data-item">
              <label className="order-detail-label">UOM:</label>
              {' ' + selectedOrder.uom}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Delivery Location:</label>
              {' ' + selectedOrder.locationID}
            </div>
            <div className="data-item">
              <label className="order-detail-label">wO Limit Price:</label>
              {' ' + selectedOrder.wOlimitPrice}
            </div>
            <div className="data-item">
              <label className="order-detail-label">wO Stop Price:</label>
              {' ' + selectedOrder.wOstopPrice}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Delivery Start Date:</label>
              {' ' + selectedOrder.deliveryStart}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Delivery End Date:</label>
              {' ' + selectedOrder.deliveryEnd}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Last Audit Date:</label>
              {' ' + selectedOrder.auditWHEN}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Legacy ID:</label>
              {' ' + selectedOrder.legacyID}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Audit By:</label>
              {' ' + selectedOrder.auditWHO}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Vendor:</label>
              {selectedVendor
                ? ' ' +
                  selectedOrder.vendorID +
                  ' - ' +
                  selectedVendor.vendorName
                : ''}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Item Type:</label>
              {' ' + selectedOrder.itemType}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Verified?:</label>
              {' ' + selectedOrder.verified}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Filled?:</label>
              {' ' + selectedOrder.filled}
            </div>
          </div>
          {/* /Second Column */}
          <div className="market-detail-column">
            <div className="data-item">
              <label className="order-detail-label">Locked?:</label>
              {' ' + selectedOrder.locked}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Option Month:</label>
              {' ' + selectedOrder.optionMonth}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Option Year:</label>
              {' ' + selectedOrder.optionYear}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Forecast Type:</label>
              {' ' + selectedOrder.forecastType}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Exchange Type:</label>
              {' ' + selectedOrder.exchangeType}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Shipment:</label>
              {' ' + selectedOrder.shipment}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Note:</label>
              {' ' + selectedOrder.note}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Alt Location:</label>
              {' ' + selectedOrder.altLocation}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Alt Vendor: :</label>
              {' ' + selectedOrder.altVendor}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Option Type:</label>
              {' ' + selectedOrder.optionType}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Option Action:</label>
              {' ' + selectedOrder.optionAction}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Strike Price:</label>
              {' ' + selectedOrder.strikePrice}
            </div>
            <div className="data-item">
              <label className="order-detail-label">wO Type:</label>
              {' ' + selectedOrder.wOtype}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Limit Price:</label>
              {' ' + selectedOrder.limitPrice}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Exchange Price:</label>
              {' ' + selectedOrder.exchangePrice}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Exchange Account:</label>
              {' ' + selectedOrder.exchangeAccount}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Basis Price:</label>
              {' ' + selectedOrder.basisPrice}
            </div>
            <div className="data-item">
              <label className="order-detail-label">FoB Price:</label>
              {' ' + selectedOrder.foBprice}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Contract Number:</label>
              {' ' + selectedOrder.contractNumber}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Ship Mode:</label>
              {' ' + selectedOrder.shipMode}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Alt Contract Type:</label>
              {' ' + selectedOrder.altContractType}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Fill Date:</label>
              {' ' + selectedOrder.fillDate}
            </div>
            <div className="data-item">
              <label className="order-detail-label">Fill Price:</label>
              {' ' + selectedOrder.fillPrice}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <LoadingIcons.Oval stroke="#006730" />
      </div>
    );
  }
}

// MarkerOrderDetail.propTypes = {
//   onModeChange: PropTypes.string,
//   returnBack: PropTypes.string,
// };

import React, { useState, useEffect } from 'react';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@progress/kendo-react-buttons';
import { Label } from '@progress/kendo-react-labels';
import '../../NewOrdersStyle.css';
import {
  FormRadioGroup,
  CustomFormRadioGroup,
  FormDropDownList,
  FormInput,
  FormDatePicker,
  FormTextArea,
  FormAutoComplete,
  FormNumericTextBox,
} from '../../../Common/Forms/form-components';
import {
  dateValidator,
  requiredValidator,
} from '../../../Common/Forms/validators';
import {
  retrieveLOVList,
  retrieveLocationLOVList,
  retrieveLocationInfo,
  retrieveVendorLOVList,
  // retrieveVendorInfo,
} from 'store/modules/lov';
import {
  tradeTypeFormModel,
  regionFormModel,
  orderGroupModel,
  itemTypeModel,
  uomModel,
  forecastTypeModel,
  optionsModel,
  buyOrSellModel,
  sFuturesMonthModel,
  smFuturesMonthModel,
  cwFuturesMonthModel,
  yearModel,
  otherOrderTypeModel,
  exchangeOrderTypeModel,
  exchangeTypeModel,
  workingOrderTypeModel,
  shipmentModel,
} from 'utils/forms/newHedgeOrderModel';
import { clearSubmitNewOrder } from 'store/modules/order';
import { filterBy } from '@progress/kendo-data-query';
import { data } from 'jquery';

export default function NewHedgeOrder(props) {
  const dispatch = useDispatch();
  const {
    commodityLOV,
    loadedAltContractType,
    altContractTypeLOV,
    commoditySymbolLOV,
    shipModeLOV,
    loadedShipMode,
    locationLOVList,
    vendorLOVList,
    locationInfo,
    loadedLocationInfo,
    // vendorInfo,
    // loadedVendorInfo,
  } = useSelector((state) => state.lov);

  const [altContractType, setAltContractType] = useState(altContractTypeLOV);
  const [deliveryLocation, setDeliveryLocation] = useState();
  const [orderTypeModel, setOrderTypeModel] = useState();
  const [commodity, setCommodity] = useState();
  const [commoditySymbol, setCommoditySymbol] = useState();
  const [futuresMonth, setFuturesMonth] = useState();
  const [shipMode, setShipMode] = useState(shipModeLOV);
  const [locationID, setLocationID] = useState(locationInfo);
  const [vendorName, setVendorName] = useState();
  const [locationName, setLocationName] = useState();
  const [vendorID, setVendorID] = useState();
  const [itemType, setItemType] = useState('');
  const [commodityValue, setCommodityValue] = useState('');

  const [isOptions, setIsOptions] = useState(false);
  const [isExchange, setIsExchange] = useState(false);
  const [isWorkingOrder, setIsWorkingOrder] = useState(false);
  const [isFOB, setIsFOB] = useState(false);
  const [showFuturesOrOptions, setShowFuturesOrOptions] = useState(true);
  const [showOrderType, setShowOrderType] = useState(true);
  const [isLimit, setIsLimit] = useState(false);

  const [formKey, setFormKey] = useState(1);
  const [initialValues, setInitialValues] = useState(props.initialValues);

  const [multiplier, setMultiplier] = useState();

  useEffect(() => {
    dispatch(retrieveVendorLOVList());
    dispatch(clearSubmitNewOrder());
  }, []);

  useEffect(() => {
    dispatch(retrieveLOVList('AltContractType'));
    if (altContractTypeLOV) {
      setAltContractType(altContractTypeLOV);
    }
  }, [loadedAltContractType]);

  useEffect(() => {
    dispatch(retrieveLOVList('ShipMode'));
    if (shipModeLOV) {
      setShipMode(shipModeLOV);
    }
  }, [loadedShipMode]);

  useEffect(() => {
    setDeliveryLocation(locationLOVList);
  }, [locationLOVList]);

  useEffect(() => {
    setCommodity(commodityLOV);
  }, [commodityLOV]);

  useEffect(() => {
    if (itemType === 'Soybean Meal' && commodityValue === 'SBM') {
      setCommoditySymbol(['SM']);
    } else {
      setCommoditySymbol(commoditySymbolLOV);
    }
  }, [commoditySymbolLOV]);

  useEffect(() => {
    if (initialValues !== undefined) {
      handleCommoditySymbolChange(initialValues.commoditySymbol);
    }
  }, []);

  useEffect(() => {
    if (loadedLocationInfo) {
      setLocationID(locationInfo);
    }
  }, [locationInfo]);

  useEffect(() => {
    if (initialValues !== undefined) {
      const a = initialValues;
      if (a.contractType === 'exchange' || a.contractType === 'Exchange') {
        setIsExchange(true);
        setShowFuturesOrOptions(false);
        setShowOrderType(true);
        setOrderTypeModel(exchangeOrderTypeModel);
      } else if (a.contractType === 'Basis' || a.contractType === 'basis') {
        setIsExchange(false);
        setShowFuturesOrOptions(false);
        setShowOrderType(false);
        setOrderTypeModel(otherOrderTypeModel);
      } else {
        setIsExchange(false);
        setShowFuturesOrOptions(true);
        setShowOrderType(true);
        setOrderTypeModel(otherOrderTypeModel);
      }
      if (a.orderType === 'Working Order' || a.orderType === 'working order') {
        setIsWorkingOrder(true);
        setIsLimit(false);
      } else if (a.orderType === 'Limit' || a.orderType === 'limit') {
        setIsLimit(true);
        setIsWorkingOrder(false);
      } else {
        setIsWorkingOrder(false);
        setIsLimit(false);
      }
      if (a.forecastType === 'Options') {
        setIsOptions(true);
      }
      if (a.shipment === 'FOB Freight') {
        setIsFOB(true);
      } else {
        setIsFOB(false);
      }

      if (a.section !== null) {
        dispatch(retrieveLocationLOVList(a.section));
      }
    }
  }, []);

  useEffect(() => {
    if (props.isUpdate) {
      if (initialValues['itemType'] === 'Soybean Meal') {
        setMultiplier(100);
      } else if (initialValues['itemType'] === 'Other Ingredient') {
        setMultiplier(36);
      } else {
        setMultiplier(5000);
      }

      if (initialValues['commoditySymbol'] === 'S') {
        setFuturesMonth(sFuturesMonthModel);
      } else if (
        initialValues['commoditySymbol'] === 'SM' ||
        initialValues['commoditySymbol'] === 'SBM'
      ) {
        setFuturesMonth(smFuturesMonthModel);
      } else {
        setFuturesMonth(cwFuturesMonthModel);
      }
    }
  }, []);

  const handleRegionChange = (e) => {
    dispatch(retrieveLocationLOVList(e.value));
  };

  const handleCommodityChange = (e) => {
    setCommodityValue(e.value);
    dispatch(retrieveLOVList('CommoditySymbol', e.value));
  };

  const handleCommoditySymbolChange = (e) => {
    if (e.value === 'S') {
      setFuturesMonth(sFuturesMonthModel);
    } else if (e.value === 'SM' || e.value === 'SBM') {
      setFuturesMonth(smFuturesMonthModel);
    } else {
      setFuturesMonth(cwFuturesMonthModel);
    }
  };

  const handleContractTypeChange = (e) => {
    if (e.value === 'exchange' || e.value === 'Exchange') {
      setIsExchange(true);
      setShowFuturesOrOptions(false);
      setShowOrderType(true);
      setOrderTypeModel(exchangeOrderTypeModel);
    } else if (e.value === 'Basis' || e.value === 'basis') {
      setIsExchange(false);
      setShowFuturesOrOptions(false);
      setShowOrderType(false);
      setOrderTypeModel(otherOrderTypeModel);
    } else {
      setIsExchange(false);
      setShowFuturesOrOptions(true);
      setShowOrderType(true);
      setOrderTypeModel(otherOrderTypeModel);
    }
  };

  const handleForecastTypeChange = (e) => {
    if (e.value === 'Options' || e.value === 'options') {
      setIsOptions(true);
    } else {
      setIsOptions(false);
    }
  };

  const handleOrderTypeChange = (e) => {
    if (e.value === 'Working Order' || e.value === 'working order') {
      setIsWorkingOrder(true);
      setIsLimit(false);
    } else if (e.value === 'Limit' || e.value === 'limit') {
      setIsLimit(true);
      setIsWorkingOrder(false);
    } else {
      setIsWorkingOrder(false);
      setIsLimit(false);
    }
  };

  const handleShipmentChange = (e) => {
    if (e.value === 'FOB Freight') {
      setIsFOB(true);
    } else {
      setIsFOB(false);
    }
  };

  const handleLocationChange = (e) => {
    setLocationName(e.value);
    dispatch(retrieveLocationInfo(e.value));
  };

  // useEffect(() => {
  //   if (loadedVendorInfo) {
  //     setVendorID(vendorInfo);
  //   }
  // }, [vendorInfo]);

  const [vendorState, setVendorState] = useState({
    data: vendorLOVList,
    value: '',
    opened: false,
    loading: false,
  });

  const handleVendorChange = (event) => {
    const value = event.target.value;

    const filterData = (value) => {
      const data = vendorLOVList;
      const filter = {
        value: value,
        // operator: 'startswith',
        operator: 'contains',
        ignoreCase: true,
      };
      return filterBy(data, filter);
    };

    const stateData =
      value.length < 3
        ? {
            data: vendorLOVList,
            opened: false,
            loading: true,
          }
        : {
            data: filterData(value),
            opened: true,
            loading: false,
          };

    if (value.length < 0 || value.length === 0) {
      stateData.loading = false;
    }

    const eventType = event.nativeEvent.type;
    const nativeEvent = event.nativeEvent;
    const valueSelected =
      eventType === 'click' ||
      (eventType === 'keydown' && nativeEvent.keyCode === 13);

    if (valueSelected && stateData.data.includes(value)) {
      stateData.opened = false;
      stateData.loading = false;
      setVendorName(value);
      // dispatch(retrieveVendorInfo(value));
      // setVendorID(vendorIDValue);
    }

    setVendorState({
      value: value,
      ...stateData,
    });
  };

  const resetForm = () => {
    setInitialValues({});
    setFormKey(formKey + 1);
  };

  const handleSubmit = (dataItem) => {
    if (!('id' in dataItem)) {
      Object.assign(dataItem, {
        locationID: locationID,
      });
    } else {
      Object.assign(dataItem, {
        vendorID: initialValues.vendorID,
      });
      dataItem.locationID = locationID;
    }
    props.onModeChange('detailreview');
    props.formValue(dataItem);
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      key={formKey}
      render={(formRenderProps) => (
        <FormElement>
          <div className="form-first-column">
            <div className="contract-type" id="field-container">
              <div className="contract-type-field">
                <Field
                  id={'contractType'}
                  name={'contractType'}
                  layout={'horizontal'}
                  component={FormRadioGroup}
                  data={tradeTypeFormModel}
                  onChange={handleContractTypeChange}
                  validator={requiredValidator}
                />
              </div>
            </div>
            <div className="locationSection" id="field-container">
              <div className="locationSection-field">
                <Field
                  id={'section'}
                  name={'section'}
                  layout={'horizontal'}
                  component={FormRadioGroup}
                  data={regionFormModel}
                  onChange={handleRegionChange}
                  validator={requiredValidator}
                />
              </div>
            </div>
            <div className="order-group" id="field-container">
              <div className="order-group-field">
                <Field
                  id={'group'}
                  name={'group'}
                  layout={'horizontal'}
                  component={FormRadioGroup}
                  data={orderGroupModel}
                  validator={requiredValidator}
                />
              </div>
            </div>
            <div className="delivery-loc" id="field-container">
              <Field
                id={'locationName'}
                name={'locationName'}
                label={'Delivery Location'}
                component={FormDropDownList}
                data={deliveryLocation}
                onChange={handleLocationChange}
                validator={requiredValidator}
              />
            </div>
            <div className="vendor-farmer" id="field-container">
              <Field
                id={'vendorName'}
                name={'vendorName'}
                label={'Farmer/Vendor'}
                component={FormAutoComplete}
                data={vendorState.data}
                value={vendorState.value}
                onChange={handleVendorChange}
                opened={vendorState.opened}
                loading={vendorState.loading}
                validator={requiredValidator}
              />
            </div>
            <div className="item-type" id="field-container">
              <Field
                id={'itemType'}
                name={'itemType'}
                label={'Item Type'}
                component={FormDropDownList}
                data={itemTypeModel}
                validator={requiredValidator}
                onChange={(e) => {
                  setItemType(e.target.value);
                  dispatch(retrieveLOVList('Commodity', e.target.value));
                  const itemTypeValue = formRenderProps.valueGetter('itemType');
                  if (itemTypeValue === 'Soybean Meal') {
                    setMultiplier(100);
                    formRenderProps.onChange('uom', {
                      value: 'Tons',
                    });
                    formRenderProps.onChange('commodity', {
                      value: 'SBM',
                    });
                    formRenderProps.onChange('commoditySymbol', {
                      value: 'SM',
                    });
                  } else if (itemTypeValue === 'Other Ingredient') {
                    setMultiplier(36);
                    formRenderProps.onChange('uom', {
                      value: 'Tons',
                    });
                    formRenderProps.onChange('commodity', {
                      value: '',
                    });
                    formRenderProps.onChange('commoditySymbol', {
                      value: '',
                    });
                  } else {
                    setMultiplier(5000);
                    formRenderProps.onChange('uom', {
                      value: 'Bushels',
                    });
                    formRenderProps.onChange('commodity', {
                      value: '',
                    });
                    formRenderProps.onChange('commoditySymbol', {
                      value: '',
                    });
                  }
                  const commoditySymbolValue =
                    formRenderProps.valueGetter('commoditySymbol');
                  if (
                    commoditySymbolValue === 'SM' ||
                    commoditySymbolValue === 'SBM'
                  ) {
                    setFuturesMonth(smFuturesMonthModel);
                  }
                }}
              />
            </div>
            <div className="quantity-field" id="field-container">
              <Field
                id={'quantity'}
                name={'quantity'}
                label={'Quantity'}
                // component={FormInput}
                component={FormNumericTextBox}
                validator={requiredValidator}
                onChange={(e) => {
                  const quantityValue = e.target.value;
                  const itemTypeValue = formRenderProps.valueGetter('itemType');
                  if (itemTypeValue === 'Soybean Meal') {
                    formRenderProps.onChange('contractCount', {
                      value: Math.round(quantityValue / multiplier),
                    });
                  } else if (itemTypeValue === 'Other Ingredient') {
                    formRenderProps.onChange('contractCount', {
                      value: Math.round(quantityValue / multiplier),
                    });
                  } else {
                    formRenderProps.onChange('contractCount', {
                      value: Math.round(quantityValue / multiplier),
                    });
                  }
                }}
              />
            </div>
            <div className="uom-field" id="field-container">
              <Field
                layout="vertical"
                id={'uom'}
                name={'uom'}
                component={FormRadioGroup}
                data={uomModel}
              />
            </div>
            <div className="commodity" id="field-container">
              <Field
                id={'commodity'}
                name={'commodity'}
                label={'Commodity'}
                component={FormDropDownList}
                data={commodity}
                onChange={(e) => {
                  const commodityTypeValue =
                    formRenderProps.valueGetter('commodity');
                  if (commodityTypeValue === 'Soybeans') {
                    formRenderProps.onChange('commoditySymbol', {
                      value: 'S',
                    });
                  } else if (commodityTypeValue === 'Corn') {
                    formRenderProps.onChange('commoditySymbol', {
                      value: 'C',
                    });
                  } else {
                    formRenderProps.onChange('commoditySymbol', {
                      value: '',
                    });
                    handleCommodityChange(e);
                  }
                  const commoditySymbolValue =
                    formRenderProps.valueGetter('commoditySymbol');
                  if (commoditySymbolValue === 'S') {
                    setFuturesMonth(sFuturesMonthModel);
                  } else {
                    handleCommoditySymbolChange(commoditySymbolValue);
                  }
                }}
              />
            </div>
            {showFuturesOrOptions && (
              <div className="forecast-type" id="field-container">
                <Field
                  id={'forecastType'}
                  name={'forecastType'}
                  label={'Futures or Options'}
                  layout={'horizontal'}
                  component={CustomFormRadioGroup}
                  data={forecastTypeModel}
                  onChange={handleForecastTypeChange}
                  validator={requiredValidator}
                />
              </div>
            )}
            {isOptions && (
              <div className="options-container" id="field-container">
                <div className="options">
                  <Field
                    id={'optionType'}
                    name={'optionType'}
                    label={'Options'}
                    layout={'horizontal'}
                    component={CustomFormRadioGroup}
                    data={optionsModel}
                  />
                </div>
                <div className="buy-sell" id="field-container">
                  <Field
                    id={'buyOrSell'}
                    name={'optionAction'}
                    label={'Buy or Sell'}
                    layout={'horizontal'}
                    component={CustomFormRadioGroup}
                    data={buyOrSellModel}
                  />
                </div>
                {/* <div className="option-month" id="field-container">
                  <Field
                    id={'optionMonth'}
                    name={'optionMonth'}
                    label={'Option Month'}
                    component={FormInput}
                  />
                </div> */}
                <div className="strike-price" id="field-container">
                  <Field
                    id={'strikePrice'}
                    name={'strikePrice'}
                    label={'Strike Price'}
                    component={FormInput}
                  />
                </div>
              </div>
            )}
            <div className="number-of-contracts" id="field-container">
              <Field
                id={'numberOfContracts'}
                name={'contractCount'}
                label={'Number of Contracts'}
                component={FormNumericTextBox}
                onChange={(e) => {
                  const contractCountValue = e.target.value;
                  const itemTypeValue = formRenderProps.valueGetter('itemType');
                  if (itemTypeValue === 'Soybean Meal') {
                    formRenderProps.onChange('quantity', {
                      value: contractCountValue * multiplier,
                    });
                  } else if (itemTypeValue === 'Other Ingredient') {
                    formRenderProps.onChange('quantity', {
                      value: contractCountValue * multiplier,
                    });
                  } else {
                    formRenderProps.onChange('quantity', {
                      value: contractCountValue * multiplier,
                    });
                  }
                }}
              />
            </div>
            <div className="commodity-symbol" id="field-container">
              <Field
                id={'commoditySymbol'}
                name={'commoditySymbol'}
                label={'Commodity Symbol'}
                component={FormDropDownList}
                data={commoditySymbol}
                validator={requiredValidator}
                onChange={handleCommoditySymbolChange}
              />
            </div>
            <div className="futures-month-field" id="field-container">
              <Field
                id={'futuresMonth'}
                name={'futuresMonth'}
                label={'Futures Month'}
                component={FormDropDownList}
                data={futuresMonth}
                validator={requiredValidator}
              />
            </div>
            <div className="year-field" id="field-container">
              <Field
                id={'optionYear'}
                name={'optionYear'}
                label={'Year'}
                component={FormDropDownList}
                data={yearModel}
                validator={requiredValidator}
              />
            </div>
            {showOrderType && (
              <div className="order-type" id="field-container">
                <Field
                  id={'orderType'}
                  name={'orderType'}
                  label={'Order Type'}
                  component={FormDropDownList}
                  data={orderTypeModel}
                  onChange={handleOrderTypeChange}
                  validator={requiredValidator}
                />
              </div>
            )}
            {isExchange && (
              <div className="exchange-type" id="field-container">
                <Field
                  layout="horizontal"
                  id={'exchangeType'}
                  name={'exchangeType'}
                  label={'Exchange Type'}
                  component={CustomFormRadioGroup}
                  data={exchangeTypeModel}
                />
              </div>
            )}
          </div>
          <div className="form-second-column">
            <div className="basis-price" id="field-container">
              <Field
                id={'basisPrice'}
                name={'basisPrice'}
                label={'Basis Price'}
                component={FormInput}
              />
            </div>
            {isLimit && (
              <div className="limit-order-price" id="field-container">
                <Field
                  id={'limitPrice'}
                  name={'limitPrice'}
                  label={'Price'}
                  component={FormInput}
                />
              </div>
            )}
            {isWorkingOrder && (
              <>
                <div className="working-order-type" id="field-container">
                  <Field
                    id={'wOtype'}
                    name={'wOtype'}
                    label={'Working Order Type'}
                    component={FormDropDownList}
                    data={workingOrderTypeModel}
                  />
                </div>
                <div className="limit-price" id="field-container">
                  <Field
                    id={'wOlimitPrice'}
                    name={'wOlimitPrice'}
                    label={'Limit Price'}
                    component={FormInput}
                    validator={requiredValidator}
                  />
                </div>
                <div className="stop-price" id="field-container">
                  <Field
                    id={'wOstopPrice'}
                    name={'wOstopPrice'}
                    label={'Stop Price'}
                    component={FormInput}
                  />
                </div>
              </>
            )}
            {isExchange && (
              <>
                <div className="exchange-price" id="field-container">
                  <Field
                    id={'exchangePrice'}
                    name={'exchangePrice'}
                    label={'Price'}
                    component={FormInput}
                  />
                </div>
                <div className="account-name-number" id="field-container">
                  <Field
                    id={'exchangeAccount'}
                    name={'exchangeAccount'}
                    label={'Account Name & Number'}
                    component={FormInput}
                    validator={requiredValidator}
                  />
                </div>
              </>
            )}
            <div className="delivery-period-container" id="field-container">
              <Label className="delivery-period-label">Delivery Period</Label>
              <div className="delivery-date-range" id="field-container">
                <Field
                  id={'deliveryStart'}
                  name={'deliveryStart'}
                  label={'Start Date'}
                  component={FormDatePicker}
                  validator={dateValidator}
                />
                <Field
                  id={'deliveryEnd'}
                  name={'deliveryEnd'}
                  label={'End Date'}
                  component={FormDatePicker}
                  validator={dateValidator}
                />
              </div>
            </div>
            <div className="shipment" id="field-container">
              <Field
                id={'shipment'}
                name={'shipment'}
                label={'Shipment'}
                layout={'horizontal'}
                component={CustomFormRadioGroup}
                data={shipmentModel}
                onChange={handleShipmentChange}
                validator={requiredValidator}
              />
            </div>
            {isFOB && (
              <div className="shipment-price" id="field-container">
                <Field
                  id={'foBprice'}
                  name={'foBprice'}
                  label={'Price'}
                  component={FormInput}
                />
              </div>
            )}

            <div className="ship-mode" id="field-container">
              <Field
                id={'shipMode'}
                name={'shipMode'}
                label={'Ship Mode'}
                component={FormDropDownList}
                data={shipMode}
                validator={requiredValidator}
              />
            </div>
            <div className="contract-number" id="field-container">
              <Field
                id={'contractNumber'}
                name={'contractNumber'}
                label={'Contract Number'}
                component={FormInput}
              />
            </div>
            <div className="alt-contract-type" id="field-container">
              <Field
                id={'altContractType'}
                name={'altContractType'}
                label={'Contract Type'}
                component={FormDropDownList}
                data={altContractType}
              />
            </div>
            <div className="other-delivery-loc" id="field-container">
              <Field
                id={'altLocation'}
                name={'altLocation'}
                label={'Other Delivery Locations'}
                component={FormInput}
              />
            </div>
            <div className="alt-vendor" id="field-container">
              <Field
                id={'altVendor'}
                name={'altVendor'}
                label={'Alt. Vendor'}
                component={FormInput}
              />
            </div>
            <div className="additional-notes" id="field-container">
              <Field
                id={'additionalNotes'}
                name={'note'}
                label={'Additional Notes'}
                component={FormTextArea}
              />
            </div>
            <div className="k-form-buttons">
              <Button
                themeColor={'primary'}
                type={'submit'}
                disabled={!formRenderProps.allowSubmit}
              >
                Submit
              </Button>
              <Button onClick={resetForm}>Clear</Button>
            </div>
          </div>
        </FormElement>
      )}
    />
  );
}
